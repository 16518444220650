import { template as template_10e29e6b563f4e3fbe215ad1fca63d0f } from "@ember/template-compiler";
const FKLabel = template_10e29e6b563f4e3fbe215ad1fca63d0f(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
