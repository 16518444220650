import { template as template_822810d092514501b28c4eff3c4b1e3b } from "@ember/template-compiler";
const FKControlMenuContainer = template_822810d092514501b28c4eff3c4b1e3b(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
