import { template as template_5371e5ec54694a51aa23e944b4cab1a6 } from "@ember/template-compiler";
const SidebarSectionMessage = template_5371e5ec54694a51aa23e944b4cab1a6(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
